import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  private apiCount = 0;
  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  isLoading$ = this.isLoadingSubject.asObservable();
  spinnerService = inject(NgxSpinnerService);

  constructor() {}

  showLoader() {
    this.spinnerService.show();

    if (this.apiCount === 0) {
      this.isLoadingSubject.next(true);
    }
    this.apiCount++;
  }

  hideLoader() {
    if (this.apiCount >= 1) {
      this.apiCount--;
    }
    if (this.apiCount === 0) {
      this.isLoadingSubject.next(false);
    }
    this.spinnerService.hide();
  }
}
